import './style.css';
import { createRoot } from 'react-dom/client';
import React from 'react'
import R3fPreloader from './Experience/Preloader/R3fPreloader.jsx';
import Experience from './Experience/Experience.js';

function isMobile() {
    return /android|webos|iphone|ipad/i.test(navigator.userAgent);
}
  
if (isMobile()) 
{
    //console.log("You are using a mobile device");

    const deviceOrientationLockDiv = document.getElementById('orientation-lock-div');
    deviceOrientationLockDiv.style.visibility = 'hidden';

    // Loading Progress bar
    const r3fDivRef = document.getElementById('webgl-react-three-fiber');    
    r3fDivRef.style.opacity = 0;
    const sceneTransitionCurtain = document.getElementById('scene-transition-curtain');
    sceneTransitionCurtain.style.backgroundColor = 'black';
    const loadingPercentElement = document.querySelector('#loading-percent');
    const mobilePreloaderElement = document.querySelector('#mobile-preloader');

    // Initialize the Experience with the CSS 3D Renderer and Web GL 3D Renderer
    const experience = new Experience(document.querySelector('#webgl-three-js'), false);
    const resourcesRef = experience.resources;
    
    let progressRatio = 0;
    resourcesRef.on('itemLoaded', () => {        
        progressRatio = (resourcesRef.loaded + 1) / resourcesRef.toLoad;
        //console.log((((resourcesRef.loaded + 1)/ resourcesRef.toLoad) * 100) + "%");
        const percent = Math.floor(progressRatio * 100);
        loadingPercentElement.innerHTML = `${percent}%`;
    });
    
    // On loading complete
    resourcesRef.on('ready', () => {        
        loadingPercentElement.innerHTML = `100%`;
        mobilePreloaderElement.style.opacity = 0;
        sceneTransitionCurtain.style.opacity = 0;
        document.body.style.cursor = 'auto';
        experience.isSceneRenderingEnabled = checkOrientation();

        const timer = setTimeout(() => {
            r3fDivRef.remove();
            sceneTransitionCurtain.remove();
            mobilePreloaderElement.remove();
        }, 3000);
    });

    // Orientation detection
    function checkOrientation() {
        if (window.screen.orientation.type == 'portrait-primary' || window.screen.orientation.type == 'portrait-secondary')
        {
            //alert('Please switch to landscape mode for the best experience.');
            deviceOrientationLockDiv.style.visibility = 'visible';
            return false;
        }
        else
        {
            deviceOrientationLockDiv.style.visibility = 'hidden';
            return true;
        }            
    }
    
    // On screen.orientation.change event
    if (window.screen.orientation) 
    {
        window.screen.orientation.addEventListener("change", function() 
        {
            console.log("New orientation:", window.screen.orientation.type);
            experience.isSceneRenderingEnabled = checkOrientation();
        });
    }

    
}
// Load the R3F pre loader on a desktop website 
else 
{
    //console.log("You are using a desktop device");

    const mobilePreloaderElement = document.querySelector('#mobile-preloader');
    mobilePreloaderElement.remove();
    const mobileOrientationCheckElement = document.querySelector('#orientation-lock-div');
    mobileOrientationCheckElement.remove();

    //DREI Based preloader
    createRoot(document.querySelector('#webgl-react-three-fiber')).render(
        <>
            <R3fPreloader />
        </>    
    );
}
  



//-----------------------------------------Deprecated--------------------------------------

// Native Three JS based preloader
/**
const preLoader = new PreLoader();

//Wait for preLoader 'ready' event
preLoader.on('ready', () =>
{
    // Initialize the Experience with the CSS 3D Renderer and Web GL 3D Renderer
    const experience = new Experience(document.querySelector('#webgl'));        
});
 */

// v1 experience without css3D renderer
//const experience = new Experience(document.querySelector('canvas.webgl'));
































/**  Mesh Reflector test 
 
import * as THREE from 'three'
import MeshReflectorMaterial from './Experience/Utils/MeshReflectorMaterial'
import { OrbitControls } from "three/examples/jsm/controls/OrbitControls"
import { RGBELoader } from "three/examples/jsm/loaders/RGBELoader";



const scene = new THREE.Scene()

const camera = new THREE.PerspectiveCamera(75, window.innerWidth / window.innerHeight, 0.1, 100)
camera.position.set(2, 2, -2)
scene.add(camera)

const renderer = new THREE.WebGLRenderer({
    //canvas: document.querySelector('.webgl')
})
renderer.outputEncoding = THREE.sRGBEncoding
renderer.setSize(window.innerWidth, window.innerHeight)

// temp
const canvas = document.querySelector('#webgl');
canvas.appendChild(renderer.domElement);

const controls = new OrbitControls(camera, renderer.domElement);




new RGBELoader().load("/env.hdr", tex => {
    const pmremGenerator = new THREE.PMREMGenerator(renderer);
    pmremGenerator.compileEquirectangularShader();

    scene.environment = scene.background = pmremGenerator.fromEquirectangular(tex).texture
});



const cube = new THREE.Mesh(new THREE.BoxGeometry(1, 1, 1), new THREE.MeshStandardMaterial({ color: 0xff00ff }))
scene.add(cube)

const torusKnot = new THREE.Mesh(new THREE.TorusKnotGeometry(0.5, 0.1, 100, 16), new THREE.MeshStandardMaterial({ color: 0x00ff00 }));
torusKnot.position.set(2, 0.5, 2)
scene.add(torusKnot)

const plane = new THREE.Mesh(new THREE.PlaneGeometry(10, 10))
plane.position.y = -0.5
plane.rotation.x = -Math.PI / 2
scene.add(plane)



plane.material = new MeshReflectorMaterial(renderer, camera, scene, plane, {
    resolution: 1024,
    blur: [512, 128],
    mixBlur: 2.5,
    mixContrast: 1.5,
    mirror: 1
});

plane.material.setValues({
    roughnessMap: new THREE.TextureLoader().load("/roughness.jpg"),
    normalMap: new THREE.TextureLoader().load("/normal.png"),
    normalScale: new THREE.Vector2(0.3, 0.3)
})

// plane.material.setValues({
//     roughnessMap: new THREE.TextureLoader().load("/textures/SurfaceImperfections003_1K_var1.jpg"),
//     normalMap: new THREE.TextureLoader().load("/textures/SurfaceImperfections003_1K_Normal.jpg"),
//     normalScale: new THREE.Vector2(0.3, 0.3)
// })



const loop = () => {
    cube.rotation.y += 0.01
    torusKnot.rotation.x += 0.01
    torusKnot.rotation.z += 0.01

    plane.material.update()

    controls.update();

    renderer.render(scene, camera)

    requestAnimationFrame(loop)
}

loop()

 */