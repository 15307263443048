import Experience from './Experience.js'
import { CSS3DObject } from 'three/examples/jsm/renderers/CSS3DRenderer.js';
import * as THREE from 'three';

export default class MonitorScreenIFrame {

    constructor()
    {
        this.experienceRef = new Experience();        
        this.webGLsceneRef = this.experienceRef.scene;
        this.cssSceneRef = this.experienceRef.cssScene;
        this.sizesRef = this.experienceRef.sizes;
        this.workDenScene = this.experienceRef.world.workDenScene;
        this.cameraRef = this.experienceRef.camera;
        this.position = new THREE.Vector3(0, 0 , 0);
        this.rotation = new THREE.Euler(0, 0, 0);      
        this.debugRef = this.experienceRef.debug;        

        // //Wait for resources 'ready' event
        // this.resourcesRef.on('ready', () => {
        //     this.workDenScene = this.experienceRef.world.workDenScene;
        // });

        // Create screen        
        this.createIframe();
        //const maxOffset = this.createTextureLayers();
        //this.createEnclosingPlanes(maxOffset);
        //this.createPerspectiveDimmer(maxOffset);

        if(this.debugRef.active)
        {            
            this.cssIFrameDebugFolder = this.debugRef.ui.addFolder('CSS 3D Iframe object')           

            this.cssIFrameDebugFolder.add(this, 'printIframePostionAndRotation');            

            this.cssIFrameDebugSubFolder = this.cssIFrameDebugFolder.addFolder('Position')
            this.cssIFrameDebugSubFolder.add(this.css3Dobject.position, 'x').min(-15).max(15).step(0.00001)
            this.cssIFrameDebugSubFolder.add(this.css3Dobject.position, 'y').min(-15).max(15).step(0.00001)
            this.cssIFrameDebugSubFolder.add(this.css3Dobject.position, 'z').min(-15).max(15).step(0.00001)
            
            this.cssIFrameDebugSubFolder = this.cssIFrameDebugFolder.addFolder('Rotation')
            this.cssIFrameDebugSubFolder.add(this.css3Dobject.rotation, 'x').min(-5).max(5).step(0.00001)
            this.cssIFrameDebugSubFolder.add(this.css3Dobject.rotation, 'y').min(-5).max(5).step(0.00001)
            this.cssIFrameDebugSubFolder.add(this.css3Dobject.rotation, 'z').min(-5).max(5).step(0.00001)

            this.cssIFrameDebugFolder.add(this, 'toggleIframeElement');
           
        }
    }

    printIframePostionAndRotation()
    {
        console.log(this.css3Dobject.position);
        console.log(this.css3Dobject.rotation);
    }

    toggleIframeElement()
    {
        this.css3Dobject.visible = !this.css3Dobject.visible
    }

    /**
     * Creates the iframe for the computer screen
     */
    createIframe() {        

        // Create iframe
        this.iframe = document.createElement('iframe');

        // Set iframe attributes
        // PROD      
        this.iframe.style.width = '1760px';
        this.iframe.style.height = '880px';
        this.iframe.style.padding = '0px';        
        this.iframe.style.opacity = 1;
        this.iframe.style.overflow = 'hidden'
        this.iframe.style.color = '#00000000'
        this.iframe.allow = 'geolocation'
        this.iframe.className = 'iFrame';
        this.iframe.id = 'dekstopMonitorScreen';
        this.iframe.src = 'https://interactive-desktop-pc-portfolio.vercel.app/';
        
        // Create CSS plane
        this.createCssPlane();
    }

    /**
     * Creates a CSS plane and GL plane to properly occlude the CSS plane
     * @param element the element to create the css plane for
     */
    createCssPlane() {
        // Create CSS3D object
        this.css3Dobject = new CSS3DObject(this.iframe);

        // copy monitor position and rotation        
        this.css3Dobject.scale.set(1.5,1.5,1.5)

        // Add to CSS scene
        this.cssSceneRef.add(this.css3Dobject);

        // Create GL plane
        // Create plane geometry
        const geometry = new THREE.PlaneGeometry(
            1760,
            880
        );

        const material = new THREE.MeshBasicMaterial({ color: 0x000000 });        
        //material.side = THREE.DoubleSide;
        material.opacity = 0.0;        
        // NoBlending allows the GL plane to occlude the CSS plane
        material.blending = THREE.NoBlending;

        // Create the GL plane mesh
        this.iframeMesh = new THREE.Mesh(geometry, material);

        // Copy the position, rotation and scale of the CSS plane to the GL plane
        this.iframeMesh.position.copy(this.css3Dobject.position);
        this.iframeMesh.rotation.copy(this.css3Dobject.rotation);
        this.iframeMesh.scale.copy(this.css3Dobject.scale);

        // Add to gl scene
        this.webGLsceneRef.add(this.iframeMesh);
    }

    update()
    {
        if (this.workDenScene.monitorScreen)
            {
                // Copy World Rotation and World Position from Imported Monitor Screen object to CSS3D mesh plane
                // Get the global position of the GLTF object
                var globalPosition = new THREE.Vector3();
                this.workDenScene.monitorScreen.getWorldPosition(globalPosition);
                // Set the position of the plane to match the GLTF object's position
                this.css3Dobject.position.copy(globalPosition);
                // Get the global rotation quaternion of the GLTF object
                var globalQuaternion = new THREE.Quaternion();
                this.workDenScene.monitorScreen.getWorldQuaternion(globalQuaternion);
                // Also Rotate the plane by 90 degrees on the local Z-axis
                var localRotation = new THREE.Euler();
                localRotation.setFromQuaternion(globalQuaternion, 'XYZ'); // Convert global quaternion to local Euler rotation
                localRotation.z += Math.PI / 2; // Rotate 90 degrees on local Z-axis
                // Apply the rotation of the GLTF object to the plane
                this.css3Dobject.setRotationFromEuler(localRotation);
                // Rotate the plane by 90 degrees on the global Y-axis
                this.css3Dobject.rotateY(Math.PI / 2);
        
                this.iframeMesh.position.copy(this.css3Dobject.position);
                this.iframeMesh.rotation.copy(this.css3Dobject.rotation);
            }    
    }

}