import * as THREE from 'three';
import Experience from "../Experience.js";

export default class Workden
{
    constructor()
    {
        // get ref to Experience singleton
        this.experienceRef = new Experience();
        this.sceneRef = this.experienceRef.scene;
        this.resourcesRef = this.experienceRef.resources;
        this.timeRef = this.experienceRef.time;
        this.debugRef = this.experienceRef.debug;
        this.materials = this.experienceRef.materials;
        this.postProcessingRef = this.experienceRef.postProcessing;
        this.timeRef = this.experienceRef.time;       

        // Debug
        if (this.debugRef.active)
        {
            this.debugFolder = this.debugRef.ui.addFolder('Workden');
        }

        // Resource
        this.workDenSceneBakedItems01 = this.resourcesRef.objectsLoaded.workDenSceneMerged001;
        this.workDenSceneBakedItems02 = this.resourcesRef.objectsLoaded.workDenSceneMerged002;
        this.workDenSceneMaterialMappedItems = this.resourcesRef.objectsLoaded.workDenSceneMaterialMappedObjects;
        this.workDenSceneBakedPhotoTextureObjects = this.resourcesRef.objectsLoaded.workDenSceneMergedPhotoTextureObjects;
        this.workDenSceneMiscObjects = this.resourcesRef.objectsLoaded.workDenSceneMiscObjects;

        //console.log(this.resourcesRef.objectsLoaded);
        //console.log(this.resourcesRef.materialsLoaded);
        //console.log(this.workDenSceneBakedItems);
        //console.log(this.workDenScenePlaceholderMaterialMappedItems);

        this.parseModel();        
        this.setMaterials();        
        //this.setAnimation();
    }

    parseModel()
    {
        // Baked Objects
        this.bakedItemsModel01 = this.workDenSceneBakedItems01.scene.children[0];
        this.bakedItemsModel02 = this.workDenSceneBakedItems02.scene.children[0];
        this.bakedItemsModel01.rotation.y =  - Math.PI / 2;
        this.bakedItemsModel01.scale.set(1, 1, 1);
        this.bakedItemsModel02.rotation.y =  - Math.PI / 2;
        this.bakedItemsModel02.scale.set(1, 1, 1);

        this.workDenSceneMiscObjects = this.workDenSceneMiscObjects.scene;
        this.workDenSceneMiscObjects.rotation.y =  - Math.PI / 2;
        this.workDenSceneMiscObjects.scale.set(1, 1, 1);

        this.workDenScenePhotoTexturesBakedModel = this.workDenSceneBakedPhotoTextureObjects.scene.children[0];;
        this.workDenScenePhotoTexturesBakedModel.rotation.y =  - Math.PI / 2;
        this.workDenScenePhotoTexturesBakedModel.scale.set(1, 1, 1);

        // Interactables
        this.materialMappedItemsModel = this.workDenSceneMaterialMappedItems.scene;
        this.materialMappedItemsModel.rotation.y =  - Math.PI / 2;
        this.materialMappedItemsModel.scale.set(1, 1, 1);

        this.linkedInLogo = this.materialMappedItemsModel.children[0].children.find(child => child.name === 'linkedInLogo');
        this.behanceLogo = this.materialMappedItemsModel.children[0].children.find(child => child.name === 'behanceLogo');        
        this.resume = this.materialMappedItemsModel.children[0].children.find(child => child.name === 'resume');
        this.resumeAbridged = this.materialMappedItemsModel.children[0].children.find(child => child.name === 'resumeAbridged');
        this.githubLogo = this.materialMappedItemsModel.children[0].children.find(child => child.name === 'githubLogo');
        this.monitorScreen = this.materialMappedItemsModel.children[0].children.find(child => child.name === 'monitorScreen');
        this.draftingTableSketch = this.materialMappedItemsModel.children[0].children.find(child => child.name === 'draftingTableSketchHitbox');
        this.documentFolder = this.materialMappedItemsModel.children[0].children.find(child => child.name === 'documentFolder');
        this.backNote1 = this.materialMappedItemsModel.children[0].children.find(child => child.name === 'backNote001');
        this.backNote2 = this.materialMappedItemsModel.children[0].children.find(child => child.name === 'backNote002');
        this.backNote3 = this.materialMappedItemsModel.children[0].children.find(child => child.name === 'backNote003');
        this.monitorONOFFbutton = this.materialMappedItemsModel.children[0].children.find(child => child.name === 'monitorONOFFbutton');
        this.backNote4 = this.materialMappedItemsModel.children[0].children.find(child => child.name === 'backNote004');        
        this.backNote5 = this.materialMappedItemsModel.children[0].children.find(child => child.name === 'backNote005');
        this.backNote6 = this.materialMappedItemsModel.children[0].children.find(child => child.name === 'backNote006');
        this.backNote6 = this.materialMappedItemsModel.children[0].children.find(child => child.name === 'backNote006');
        
        // Non Interactive Objects
        this.unityLogo = this.materialMappedItemsModel.children[0].children.find(child => child.name === 'unityLogo');
        this.unrealLogo = this.materialMappedItemsModel.children[0].children.find(child => child.name === 'unrealLogo');
        this.photoshopLogo = this.materialMappedItemsModel.children[0].children.find(child => child.name === 'photoshopLogo');        
        this.blenderLogo = this.materialMappedItemsModel.children[0].children.find(child => child.name === 'blenderLogo');        
        this.threeJsLogo = this.materialMappedItemsModel.children[0].children.find(child => child.name === 'threeJsLogo');
        this.googleHomeLights = this.materialMappedItemsModel.children[0].children.find(child => child.name === 'googleHomeLights');
        this.treeLampBulb1 = this.materialMappedItemsModel.children[0].children.find(child => child.name === 'treeLampLightBulb001');
        this.treeLampBulb2 = this.materialMappedItemsModel.children[0].children.find(child => child.name === 'treeLampLightBulb002');
        this.treeLampShadesInnerSide = this.materialMappedItemsModel.children[0].children.find(child => child.name === 'treeLampShadeInnerSide');
        //this.treeLampShadesInner = this.materialMappedItemsModel.children[0].children.find(child => child.name === 'treeLampShade');
        this.hangingLampLight = this.materialMappedItemsModel.children[0].children.find(child => child.name === 'hangingLampLightBulb');
        this.hangingLampShade = this.materialMappedItemsModel.children[0].children.find(child => child.name === 'hangingLampShade');
        
        this.cpuConnectors = this.materialMappedItemsModel.children[0].children.find(child => child.name === 'cpuConnectors');
        this.cpuCooler = this.materialMappedItemsModel.children[0].children.find(child => child.name === 'cpuCooler');
        this.pcRAM = this.materialMappedItemsModel.children[0].children.find(child => child.name === 'cpuRAM');
        this.desktopPcCabinet = this.materialMappedItemsModel.children[0].children.find(child => child.name === 'desktopPCCabinet');        
        this.gpuName = this.materialMappedItemsModel.children[0].children.find(child => child.name === 'gpuNameLED');
        this.nvidiaLogo = this.materialMappedItemsModel.children[0].children.find(child => child.name === 'nvidiaLogo');
        this.cpuRadiatorFan1 = this.materialMappedItemsModel.children[0].children.find(child => child.name === 'cpuRadiatorFan001');
        this.cpuRadiatorFan2 = this.materialMappedItemsModel.children[0].children.find(child => child.name === 'cpuRadiatorFan002');
        this.cpuRadiatorFanBolts = this.materialMappedItemsModel.children[0].children.find(child => child.name === 'cpuRadiatorFanBolts');        

        this.macbookBacklitKeyboardLights = this.materialMappedItemsModel.children[0].children.find(child => child.name === 'macbookKeysBacklight');
        this.kurzgezagtLogo = this.materialMappedItemsModel.children[0].children.find(child => child.name === 'kurzgezagtLogo');
        this.phoneScreen = this.materialMappedItemsModel.children[0].children.find(child => child.name === 'cellPhoneScreen');
        this.hololensLED = this.materialMappedItemsModel.children[0].children.find(child => child.name === 'holoLensLED');
        this.macbookScreen = this.materialMappedItemsModel.children[0].children.find(child => child.name === 'macbookScreen');

        // Animated Objects
        this.gamingChairSeat = this.materialMappedItemsModel.children[0].children.find(child => child.name === 'deskChairSeat');
        this.pcCabinetFan = this.materialMappedItemsModel.children[0].children.find(child => child.name === 'cpuFan');
        this.gpuFan = this.materialMappedItemsModel.children[0].children.find(child => child.name === 'gpuFan');

        this.deskCoffeeCupSteamMarker = this.workDenSceneMiscObjects.children[0].children.find(child => child.name === 'deskCoffeeCupSteamMarker');
        this.rugCoffeeCupSteamMarker = this.workDenSceneMiscObjects.children[0].children.find(child => child.name === 'rugCoffeeCupSteamMarker');
        
    }

    setMaterials()
    {
        // this.model.traverse((child) => 
        // {
        //     if (child instanceof THREE.Mesh)
        //     {
        //         child.castShadow = true;
                
        //     }            
        // });

        this.resourcesRef.on('texturesMapped', () => {
            
            // Baked Texture Objects
            this.bakedItemsModel01.material = this.materials.workDenSceneBakedItemsModelMaterial01;
            this.bakedItemsModel02.material = this.materials.workDenSceneBakedItemsModelMaterial02;
            this.workDenScenePhotoTexturesBakedModel.material = this.materials.workDenScenePhotoTexturedObjectsMaterial;

            // Texture mapped Objects
            this.resume.material = this.materials.resumeMaterial;
            this.resumeAbridged.material = this.materials.resumeAbridgedMaterial;
            this.kurzgezagtLogo.material = this.materials.kurzgezagtLogoMaterial;
            this.phoneScreen.material = this.materials.phoneScreenMaterial;
            this.draftingTableSketch.material = this.materials.draftingTablePaintingMaterial;
            this.backNote1.material = this.materials.stickNoteMaterial;
            this.backNote2.material = this.materials.stickNoteMaterial;
            this.backNote3.material = this.materials.stickNoteMaterial;
            this.backNote4.material = this.materials.stickNoteMaterial;
            this.monitorONOFFbutton.material = this.materials.monitorONOFFbuttonMaterial;
            this.backNote5.material = this.materials.stickNoteMaterial;
            this.backNote6.material = this.materials.stickNoteMaterial;

            // Interactable Glowing Objects
            for (var i=0; i<this.linkedInLogo.children.length; i++)
            {
                //console.log(this.linkedInLogo.children[i]);
                this.linkedInLogo.children[i].material = this.materials.linkedInLogoMaterials[i];
            }
            for (var i=0; i<this.behanceLogo.children.length; i++)
            {
                //console.log(this.linkedInLogo.children[i]);
                this.behanceLogo.children[i].material = this.materials.behanceLogoMaterials[i];
            }
            this.githubLogo.material = this.materials.githubLogoMaterial;            

            // Non Interactable glowing objects
            for (var i=0; i<this.unityLogo.children.length; i++)
            {             
                this.unityLogo.children[i].material = this.materials.unityLogoMaterials[i];
            }            
            this.unrealLogo.material = this.materials.unrealLogoMaterial;
            for (var i=0; i<this.photoshopLogo.children.length; i++)
            {             
                this.photoshopLogo.children[i].material = this.materials.photoshopLogoMaterials[i];
            }
            for (var i=0; i<this.blenderLogo.children.length; i++)
            {             
                this.blenderLogo.children[i].material = this.materials.blenderLogoMaterials[i];
            }
            this.threeJsLogo.material = this.materials.threeJsLogoMaterial;
            for (var i=0; i<this.googleHomeLights.children.length; i++)
            {             
                this.googleHomeLights.children[i].material = this.materials.googleHomeLEDMaterials[i];
            }

            //console.log(this.materialMappedItemsModel.children[0]);
            this.treeLampBulb1.material = this.materials.lampBulbMaterial;
            this.treeLampBulb2.material = this.materials.lampBulbMaterial;
            this.treeLampShadesInnerSide.material = this.materials.treeLampShadeInnerMaterial;
            this.hangingLampLight.material = this.materials.lampBulbMaterial;
            this.hangingLampShade.material = this.materials.hangingLampShadeMaterial;

            this.pcCabinetFan.material = this.materials.pcRAMMaterials[0];
            this.cpuConnectors.material = this.materials.cpuConnectorsMaterial;
            for (var i=0; i<this.cpuCooler.children.length; i++)
            {             
                this.cpuCooler.children[i].material = this.materials.cpuCoolerMaterials[i];
            }
            for (var i=0; i<this.pcRAM.children.length; i++)
            {             
                this.pcRAM.children[i].material = this.materials.pcRAMMaterials[i];
            }
            for (var i=0; i<this.desktopPcCabinet.children.length; i++)
            {             
                this.desktopPcCabinet.children[i].material = this.materials.pcCabinetMaterials[i];
            }
            for (var i=0; i<this.gpuFan.children.length; i++)
            {                
                this.gpuFan.children[i].material = this.materials.gpuFanMaterials[i];
            }
            this.gpuName.material = this.materials.gpuNameMaterial;
            this.nvidiaLogo.material = this.materials.gpuNameMaterial;

            for (var i=0; i<this.cpuRadiatorFan1.children.length; i++)
            {                
                this.cpuRadiatorFan1.children[i].material = this.materials.pcCabinetRadiatorFanMaterials[i];
                this.cpuRadiatorFan2.children[i].material = this.materials.pcCabinetRadiatorFanMaterials[i];
            }

            this.macbookBacklitKeyboardLights.material = this.materials.macbookBacklitKeyboardLightMaterial;
            this.hololensLED.material = this.materials.hololensLEDMaterial;
            this.macbookScreen.material = this.materials.macbookScreenMaterial;
            this.monitorScreen.material = this.materials.monitorScreenMaterial;
            
            for (var i=0; i<this.gamingChairSeat.children.length; i++)
            {                
                this.gamingChairSeat.children[i].material = this.materials.gamingChairSeatMaterials[i];
            }

            for (var i=0; i<this.documentFolder.children.length; i++)
            {                
                this.documentFolder.children[i].material = this.materials.documentFolderMaterials[i];
            }
            
            this.sceneRef.add(this.bakedItemsModel01);
            this.sceneRef.add(this.bakedItemsModel02);
            this.sceneRef.add(this.workDenScenePhotoTexturesBakedModel);
            this.sceneRef.add(this.materialMappedItemsModel);
        })        
        

        //Test object
        // Object
        // const cubeGeometry = new THREE.BoxGeometry(1, 1, 1)
        // const cubeMaterial = new THREE.MeshBasicMaterial({
        //     color: '#ff0000'
        // })
        // const cubeMesh = new THREE.Mesh(cubeGeometry, cubeMaterial)
        // cubeMesh.position.set(0,0,0)
        // this.sceneRef.add(cubeMesh)

        // const geometry = new THREE.PlaneGeometry( 1, 1 );        
        // const plane = new THREE.Mesh( geometry, this.materials.monitorScreenMaterial );
        // this.sceneRef.add( plane )

        //console.log(this.bakedItemsModel.position);
    }

    setAnimation()
    {
        this.resourcesRef.on('texturesMapped', () => {            

        });
    }


    // setAnimation()
    // {
    //     this.animation = {};
    //     this.animation.mixer = new THREE.AnimationMixer(this.model);

    //     this.animation.actions = {};
    //     this.animation.actions.idle = this.animation.mixer.clipAction(this.foxModelResource.animations[0]);
    //     this.animation.actions.walking = this.animation.mixer.clipAction(this.foxModelResource.animations[1]);
    //     this.animation.actions.running = this.animation.mixer.clipAction(this.foxModelResource.animations[2]);

    //     this.animation.actions.current = this.animation.actions.idle;
    //     this.animation.actions.current.play();

    //     this.animation.play = (name) =>
    //     {
    //         const newAction = this.animation.actions[name];
    //         const oldAction = this.animation.actions.current;

    //         newAction.reset();
    //         newAction.play();
    //         newAction.crossFadeFrom(oldAction, 1);

    //         this.animation.actions.current = newAction;
    //     }

    //     // For Debug UI
    //     if(this.debugRef.active)
    //     {
    //         const debugObject = {
    //             playIdle : () => {this.animation.play('idle')},
    //             playWalking : () => {this.animation.play('walking')},
    //             playRunning : () => {this.animation.play('running')} 
    //         }

    //         this.debugFolder.add(debugObject, 'playIdle');
    //         this.debugFolder.add(debugObject, 'playWalking');
    //         this.debugFolder.add(debugObject, 'playRunning');
    //     }

        
    // }

    update()
    {
        //console.log("Updating Fox animation mixer");        
        //this.animation.mixer.update(this.timeRef.delta * 0.001);        

        if (this.gamingChairSeat)
        {
            this.gamingChairSeat.rotation.y = Math.sin(this.timeRef.elapsedTime * 0.0002)
        }

        if(this.gpuFan)
        {
            this.gpuFan.rotation.y += 0.02;
        }

        if(this.pcCabinetFan)
        {
            this.pcCabinetFan.rotation.x += 0.02;
        }

        if(this.cpuRadiatorFan1)
        {
            this.cpuRadiatorFan1.rotation.y += 0.03;
        }

        if(this.cpuRadiatorFan2)
        {
            this.cpuRadiatorFan2.rotation.y += 0.03;
        }


        if (this.materials)
        {
            this.materials.update();
        }
    }
}