import EventEmitter from "./EventEmitter.js";

export default class Time extends EventEmitter
{
    constructor()
    {
        super();

        // Setup
        this.start = Date.now();        
        this.current = this.start;
        this.elapsedTime = 0;
        this.deltaTime = 16;

        window.requestAnimationFrame(() =>
        {
            this.tick();
        });
    }

    tick()
    {
        //console.log('Time ticker');
        const currentTime = Date.now();        
        this.deltaTime = currentTime - this.current;
        this.current = currentTime;
        this.elapsedTime = this.current - this.start;

        // Trigger a tick event
        this.trigger('tick');

        window.requestAnimationFrame(() =>
        {
            this.tick();
        });
    }
}