import { DirectionalLightHelper, SpotLightHelper } from 'three'
import React, { Suspense, useState, useRef, useEffect } from 'react'
import { Canvas, useFrame, useThree } from '@react-three/fiber'
import { OrbitControls, MeshReflectorMaterial, useHelper, PerspectiveCamera, Center  } from '@react-three/drei'
import { EffectComposer, GodRays, Bloom } from '@react-three/postprocessing'
import LandingPageStage from './LandingPageStage';
import { easing } from 'maath'
import gsap from 'gsap'
import Experience from '../Experience'

export default function R3fPreloader() {  
  
  const [isMainSceneReadyToLoad, setIsMainSceneReadyToLoad] = useState(false);
  const [mainSceneLoadingProgress, setMainSceneLoadingProgress] = useState(0);
  const [isMainSceneReady, setMainSceneReadyFlag] = useState(false);
  const [isCameraEasingRigEnabled, setIsCameraEasingRigEnabled] = useState(false);
  const [wasDoorClicked, setWasDoorClicked] = useState(false);
  const [wasMainSceneTransitionInitiated, setWasMainSceneTransitionInitiated] = useState(false);
  
  useEffect(() => {
    const timer = setTimeout(() => {
      setIsMainSceneReadyToLoad(true);      
      //console.log("Main Scene is ready to load");

    }, 4000); // Set Main Scene ready to load flag after 4 seconds

    return () => {
      clearTimeout(timer); // Cleanup timer
      console.log("timer cleared")      
    }
  }, []);

  useEffect(() => {

    //console.log(mainSceneLoadingProgress);
    if(mainSceneLoadingProgress == 1)
    {
      setMainSceneReadyFlag(true);
      //console.log("Main Scene is loaded and ready")
    }

  }, [mainSceneLoadingProgress]);

  useEffect(() => {
    
    if (isMainSceneReady)
      setIsCameraEasingRigEnabled(true);
    
  },[isMainSceneReady]);

  const onDoorClickedHandler = () => {
    //console.log("Door clicked");
    if (isMainSceneReady)
    {
      setWasDoorClicked(true);
    }      
  };

  const onDoorZoomTransitionComplete = () => {
    //console.log("Door zoom complete")
    setWasMainSceneTransitionInitiated(true);
    // Fetch the Singleton experience instance
    const experienceRef = new Experience(null);    
    const r3fDivRef = document.getElementById('webgl-react-three-fiber');
    r3fDivRef.style.backgroundColor = 'white';
    experienceRef.isSceneRenderingEnabled = true;
    r3fDivRef.style.opacity = 0;
    const sceneTransitionCurtain = document.getElementById('scene-transition-curtain');
    sceneTransitionCurtain.style.opacity = 0;
    document.body.style.cursor = 'auto';

    const timer = setTimeout(() => {
      r3fDivRef.remove();
      sceneTransitionCurtain.remove();
    }, 4000);
  }
  
  return <>      

    <Canvas        
      gl = {{ antialias: false }}
      camera = { {
          fov: 100,
          near: 0.1,
          far: 6000,
          position: [0, 100, 3000],
          rotation: [0,0,0]
      } }
      onCreated={ onCanvasCreated }
    >
      {/* <OrbitControls 
        makeDefault 
        target = {[0,0,0]} 
        enableRotate = { true } 
        enablePan = { true }
      /> */}

      {/* <color attach="background" args={['black']} /> */}
      {/* <fog attach="fog" args={['black', 35, 60]} />         */}
      <ambientLight />

      {!wasMainSceneTransitionInitiated &&
       <CineStudio 
            loadingProgress={mainSceneLoadingProgress} 
            isMainSceneReady={isMainSceneReady}
            onDoorClicked={onDoorClickedHandler}
      />}
      {!wasMainSceneTransitionInitiated && <Floor />}     
            
      { isCameraEasingRigEnabled && !wasDoorClicked && <CameraEasingRig />}

      { wasDoorClicked && 
        <TransitionToMainExperience
          DoorZoomTransitionComplete={onDoorZoomTransitionComplete} 
        />
      }      

    </Canvas>

    { isMainSceneReadyToLoad 
    && <LoadMainExperience 
          setLoadingProgressValue={setMainSceneLoadingProgress}
       />
    }    

  </>
}

function Floor() 
{
    //Using Mesh Reflector Material
    return (
      <>
        <mesh position={[-0.511, -2.5, 46.596]} receiveShadow rotation={[-Math.PI / 2, 0, 0]} name="Floor">
          <planeGeometry args={[80, 145]} />
          <MeshReflectorMaterial
            blur={[300, 100]}
            resolution={1024}
            mixBlur={1}
            mixStrength={100}
            roughness={0.9}
            depthScale={1.2}
            minDepthThreshold={0.4}
            maxDepthThreshold={1.4}
            color="#202020"
            metalness={0.8}
          />
        </mesh>
      </>      
    );    
}

function CineStudio (props) {
  const [material, cineStudioMeshRef] = useState();
  return (
    <>      
      <LandingPageStage 
        position={[0, -2.5, 0]} 
        ref={cineStudioMeshRef} 
        meshFillAmount={props.loadingProgress}
        isMainSceneReady={props.isMainSceneReady}
        onDoorClicked={props.onDoorClicked}
      />
        {material && (
          <EffectComposer disableNormalPass multisampling={8}>
            <GodRays sun={material} exposure={0.34} decay={0.8} blur />
            <Bloom luminanceThreshold={0} mipmapBlur luminanceSmoothing={0.0} intensity={3} />
          </EffectComposer>
        )}
    </>
  );
}

const onCanvasCreated = (state) =>
{  
    // Dolly camera close Cine Studio
    gsap.to(state.camera.position, {
      duration : 2,
      ease: "power3.out",
      x: 0,
      y: 0,
      z: 30
    });

    gsap.to(state.camera, {
      duration : 4,
      ease: "power3.out",
      fov: 32,
      onUpdate: () => {
        state.camera.updateProjectionMatrix();
      }
    });
}

function CameraEasingRig () {   

  //console.log("Camera Easing Rig enabled!")
  useFrame((state, delta) => 
  {
    // easing.damp(
    //   state.camera,
    //   "fov", 
    //   20, 
    //   5, 
    //   delta);

    // state.camera.updateProjectionMatrix();
    
    easing.damp3(
      state.camera.position,
      [-0.5, 0, 15],
      10,
      delta
    )

    easing.damp3(
      state.camera.position,
      [
        Math.atan2(state.pointer.y, state.pointer.x) * 0.2, 
        Math.atan2(state.pointer.x, state.pointer.y) * 0.2, 
        state.camera.position.z
      ],
      0.4,
      delta
    )

    //console.log(state.pointer)

  })
}

const SceneDirLights = () => {
  const dirLight = useRef();  
  useHelper(dirLight, DirectionalLightHelper);  

  return (
    <>
      <directionalLight position={[-50, 0, -40]} intensity={0.7} castShadow ref={dirLight} />
    </>
  );
};

const SceneSpotLights = () => {
  const spotLight = useRef();  
  useHelper(spotLight, SpotLightHelper);

  return (
    <>      
      <spotLight 
        position={[22.782, 0, 24.042]} 
        rotation={[ Math.PI/2, 0, 0]}
        intensity={0.3} 
        angle={[Math.PI/3]}
        ref={spotLight} 
      />
    </>
  );
};

function LoadMainExperience(props) {  

  // Initialize the Main Experience with the CSS 3D Renderer and Web GL 3D Renderer
  const experience = new Experience(document.querySelector('#webgl-three-js'), false);
  const resourcesRef = experience.resources;
  
  resourcesRef.on('itemLoaded', () => {        
    props.setLoadingProgressValue((resourcesRef.loaded + 1) / resourcesRef.toLoad);
    //console.log((((resourcesRef.loaded + 1)/ resourcesRef.toLoad) * 100) + "%");
  })

  return <></>;

}

function TransitionToMainExperience(props) {

  const { camera } = useThree();
  camera.lookAt(0, -1, -20);
  gsap.to(camera.position, 
    { 
      duration: 1, 
      ease: 'linear',
      x: 0,
      y: -1,
      z: -17,
      onComplete: () => {
        //const experienceRef = new Experience(null);
        props.DoorZoomTransitionComplete();
      }
     });

    return <></>
}