import Experience from './Experience.js'
import * as THREE from 'three'

export default class Controller
{
    constructor()
    {
        // Setup
        this.experienceRef = new Experience();
        this.cameraRef = this.experienceRef.camera;
        this.resourcesRef = this.experienceRef.resources;
        this.uiManagerRef = this.experienceRef.uiManager;
        this.worldRef = this.experienceRef.world;

        this.setCameraControls();
        this.setExternalLinkControls();
        this.setInteractiveElementControls();
        this.setKeyboardControls();
    }
        
    // camera transitions and angles
    setCameraControls()
    {
        this.cameraControls = {}
        
        this.cameraControls.focusResumePile = async () =>
        {   
            this.cameraRef.transitions.focusResumePile(1.5);            
        }

        this.cameraControls.focusLogoCluster = async () =>
        {   
            this.cameraRef.transitions.focusLogoCluster(1.5);            
        }

        this.cameraControls.focusDesktopMonitor = async () =>
        {   
            this.cameraRef.transitions.focusDesktopMonitor(1.5);            
        }

        this.cameraControls.focusThreeJSlogo = async () =>
        {   
            this.cameraRef.transitions.focusThreeJSlogo(1.5);            
        }

        this.cameraControls.focusDraftingTableSketch = async () =>
        {   
            this.cameraRef.transitions.focusDraftingTableSketch(1.5);            
        }

        this.cameraControls.focusDocumentFolder = async () =>
        {   
            this.cameraRef.transitions.focusDocumentFolder(1.5);            
        }

        this.cameraControls.focusDesktopPcCabinet = async () =>
        {   
            this.cameraRef.transitions.focusDesktopPcCabinet(1.5);            
        }
    }

    setExternalLinkControls()
    {
        this.externalLinkControls = {}

        this.externalLinkControls.openResume = (resumeType) =>
        {
            if (resumeType === 'resume')
            {
                window.open('https://drive.google.com/file/d/0B1KXWDcjDiKPRTBPTHR6MVdVWm8/view?usp=drive_link&resourcekey=0-NlzxRg5LS0vKtS6G0Zl1LA', '_blank');
            }
            else if (resumeType === 'resumeAbridged')
            {
                window.open('https://drive.google.com/file/d/0B1KXWDcjDiKPWWpHX1pNY2k0cEU/view?usp=drive_link&resourcekey=0-PiCN6NgltxTuJW7UF-79yg', '_blank');
            }
        }

        this.externalLinkControls.openExternalProfile = (logoType) =>
        {
            if (logoType === 'behance')
            {
                window.open('https://www.behance.net/notobelooselyshunted', '_blank');
            }
            else if (logoType === 'github')
            {
                window.open('https://github.com/nikhilbagul', '_blank');
            }
            else if (logoType === 'linkedIn')
            {
                window.open('https://www.linkedin.com/in/nikhil-bagul-3804b575', '_blank');
            }
            else if (logoType === 'threeJSlogo')
            {
                window.open('https://threejs-journey.com/certificate/view/30337', '_blank');
            }

            
        }
    }

    setInteractiveElementControls()
    {
        this.interactiveControls = {}

        this.interactiveControls.resumeGroupClicked = async (resumeType) =>
        {            
            if (this.cameraRef.cameraState === 'resumeFocussed')
            {
                this.externalLinkControls.openResume(resumeType);
            }
            else
            {
                this.cameraControls.focusResumePile('resume');
            }
        }

        this.interactiveControls.logoClusterClicked = async (logoType) =>
        {            
            if (this.cameraRef.cameraState === 'logoFocussed')
            {
                this.externalLinkControls.openExternalProfile(logoType);
            }
            else
            {
                this.cameraControls.focusLogoCluster();            
            }
        }

        this.interactiveControls.desktopMonitorClicked = async () =>
        {
            if (this.cameraRef.cameraState === 'monitorFocussed')
            {
                
            }
            else
            {
                this.cameraControls.focusDesktopMonitor();                
                document.querySelector('#webgl-three-js').style.pointerEvents = 'none';
            }
        }
        
        this.interactiveControls.threeJSlogoClicked = async () =>
        {
            if (this.cameraRef.cameraState === 'threeJSLogoFocussed')
            {
                this.externalLinkControls.openExternalProfile('threeJSlogo');
            }
            else
            {
                this.cameraControls.focusThreeJSlogo();
            }
            
        }

        this.interactiveControls.draftingTableSketchClicked = async () =>
        {
            if (this.cameraRef.cameraState === 'draftingTableFocussed')
            {
                window.open('https://drive.google.com/drive/folders/0B1KXWDcjDiKPOHRkeWI4bUVDRjQ?resourcekey=0-gsveKENh1AEiIJ-OmLfWlQ&usp=sharing', '_blank');
            }
            else
            {
                this.cameraControls.focusDraftingTableSketch();
            }            
        }

        this.interactiveControls.documentFolderClicked = async () =>
        {            
            if (this.cameraRef.cameraState === 'documentFolderFocussed')
            {
                window.open('https://drive.google.com/drive/folders/0B1KXWDcjDiKPZHFQS1Bna2UyRzg?resourcekey=0-owh_1_fa4JQC3H2M26I-dA&usp=sharing', '_blank');
            }
            else
            {
                this.cameraControls.focusDocumentFolder();
            }
        }

        this.interactiveControls.backStickyNoteClicked = async () =>
        {
            this.cameraRef.transitions.resetToDefaultPos(1.5);
        }

        this.interactiveControls.desktopPcCabinetClicked = async () =>
        {
            if (this.cameraRef.cameraState === 'desktopPcCabinetFocussed')
            {
                
            }
            else
            {
                this.cameraControls.focusDesktopPcCabinet();
            }            
        }
    }   

    sleep(ms)
    {
        return new Promise(resolve => setTimeout(resolve, ms));
    }

    setKeyboardControls()
    {
        //Wait for resources 'ready' event
        this.resourcesRef.on('ready', () => {           

            window.addEventListener( 'keydown', (event) =>
            {
                if(event.code === 'KeyQ')
                {
                    console.log(event.code);            
                }
            
                if(event.code === 'Digit1')
                {
                    this.cameraRef.cameraInstance.position.set(-12000, 7000, 12000);              
                    //this.cameraRef.cameraInstance.rotation.reorder('YXZ');
                    this.cameraRef.cameraInstance.lookAt(new THREE.Vector3(0,2000,0));
                    this.cameraRef.cameraInstance.fov = 37;
                    this.cameraRef.cameraInstance.updateProjectionMatrix();

                    this.cameraRef.cameraParent.attach(this.cameraRef.cameraInstance);
                }
            
                if(event.code === 'Digit2')
                {                    
                    this.cameraRef.cameraParent.clear();
                    this.cameraRef.cameraInstance.matrixWorld.decompose( this.cameraRef.cameraInstance.position, this.cameraRef.cameraInstance.quaternion, this.cameraRef.cameraInstance.scale );

                    this.cameraRef.cameraInstance.position.set(0, 3000, 1000),
                    this.cameraRef.cameraInstance.lookAt(this.worldRef.monitorScreenIFrame.iframeMesh.position);
                    this.cameraRef.cameraInstance.fov = 15;
                    this.cameraRef.cameraInstance.updateProjectionMatrix();

                    console.log(this.cameraRef.cameraInstance.rotation);
                }

                if(event.code === 'Digit3')
                {                    
                    this.cameraRef.cameraParent.clear();
                    this.cameraRef.cameraInstance.matrixWorld.decompose( this.cameraRef.cameraInstance.position, this.cameraRef.cameraInstance.quaternion, this.cameraRef.cameraInstance.scale );

                    this.cameraRef.cameraInstance.position.set(0, 9000, 0);

                    var globalPosition = new THREE.Vector3();
                    this.worldRef.workDenScene.resumeAbridged.getWorldPosition(globalPosition);
                    this.cameraRef.cameraInstance.lookAt(globalPosition);
                    
                    this.cameraRef.cameraInstance.fov = 9;
                    this.cameraRef.cameraInstance.updateProjectionMatrix();

                    console.log(this.cameraRef.cameraInstance.rotation);
                }

                if(event.code === 'Digit4')
                {                    
                    this.cameraRef.cameraParent.clear();
                    this.cameraRef.cameraInstance.matrixWorld.decompose(this.cameraRef.cameraInstance.position, this.cameraRef.cameraInstance.quaternion, this.cameraRef.cameraInstance.scale);

                    this.cameraRef.cameraInstance.position.set(100, 7000, 0);
                    
                    var globalPosition = new THREE.Vector3();
                    this.worldRef.workDenScene.linkedInLogo.getWorldPosition(globalPosition);
                    this.cameraRef.cameraInstance.lookAt(globalPosition);
                    
                    this.cameraRef.cameraInstance.fov = 25;
                    this.cameraRef.cameraInstance.updateProjectionMatrix();                    
                }
            })

        });        
    }    
    
}


