import * as THREE from 'three'
import Experience from '../Experience.js'
// import chromaVertexShader from '../../shaders/chromaShaders/vertex.glsl'
// import chromaFragmentShader from '../../shaders/chromaShaders/fragment.glsl'
// import TransitionVertexShader from '../../shaders/transitionShaders/vertex.glsl'
// import TransitionFragmentShader from '../../shaders/transitionShaders/fragment.glsl'
// import SlideTransitionFragmentShader from '../../shaders/transitionShaders/slideFragment.glsl'
// import hologramVertexShader from '../../shaders/hologramShaders/vertex.glsl'
// import hologramFragmentShader from '../../shaders/hologramShaders/fragment.glsl'
// import bigScreenVertexShader from '../../shaders/bigScreenShaders/vertex.glsl'
// import bigScreenFragmentShader from '../../shaders/bigScreenShaders/fragment.glsl'
import gsap from 'gsap'

export default class Materials
{
    constructor()
    {
        this.experienceRef = new Experience()
        this.debug = this.experienceRef.debug
        this.scene = this.experienceRef.scene
        this.resourcesRef = this.experienceRef.resources;
        this.timeRef = this.experienceRef.time;
        //this.preLoader = this.experience.preLoader
        //this.config = this.experience.config       

        // Wait for textures and models to be loaded
        this.resourcesRef.on('ready', () =>
        {            
            this.mapColors();            
            this.mapTextures()
        })       

        // Debug
        this.debugObject = {}
    }
    
    mapColors()
    {
        // Glowing Materials
        this.linkedInLogoMaterials = [
            new THREE.MeshStandardMaterial({
                color: new THREE.Color('#FFFFFF'),
                emissive: new THREE.Color('#FFFFFF'),
                emissiveIntensity: 3
            }),            
            new THREE.MeshStandardMaterial({
                color: new THREE.Color('#0033FF'),
                emissive: new THREE.Color('#0033FF'),
                emissiveIntensity: 3
            })
        ];
        this.githubLogoMaterial = new THREE.MeshStandardMaterial({
            color: new THREE.Color('#FFFFFF'),
            emissive: new THREE.Color('#FFFFFF'),
            emissiveIntensity: 4
        });
        this.unityLogoMaterials = [            
            new THREE.MeshStandardMaterial({
                color: new THREE.Color('#FFFFFF'),
                emissive: new THREE.Color('#FFFFFF'),
                emissiveIntensity: 4
            }),
            new THREE.MeshStandardMaterial({
                color: new THREE.Color('#000000'),
                emissive: new THREE.Color('#000000'),
                emissiveIntensity: 4
            })
        ];
        this.behanceLogoMaterials = [            
            new THREE.MeshStandardMaterial({
                color: new THREE.Color('#2C8FE0'),
                emissive: new THREE.Color('#2C8FE0'),
                emissiveIntensity: 2
            }),
            new THREE.MeshStandardMaterial({
                color: new THREE.Color('#FFFFFF'),
                emissive: new THREE.Color('#FFFFFF'),
                emissiveIntensity: 2
            })            
        ];
        this.unrealLogoMaterial = new THREE.MeshStandardMaterial({
            color: new THREE.Color('#E7E7E7'),
            emissive: new THREE.Color('#E7E7E7'),
            emissiveIntensity: 2
        });
        this.githubLogoMaterial = new THREE.MeshStandardMaterial({
            color: new THREE.Color('#FFFFFF'),
            emissive: new THREE.Color('#FFFFFF'),
            emissiveIntensity: 4
        });
        this.photoshopLogoMaterials = [
            new THREE.MeshStandardMaterial({
                color: new THREE.Color('#001D34'),
                emissive: new THREE.Color('#001D34'),
                emissiveIntensity: 4
            }),
            new THREE.MeshStandardMaterial({
                color: new THREE.Color('#2E9CE3'),
                emissive: new THREE.Color('#2E9CE3'),
                emissiveIntensity: 4
            })
        ];
        this.blenderLogoMaterials = [
            new THREE.MeshStandardMaterial({
                color: new THREE.Color('#FF8A00'),
                emissive: new THREE.Color('#FF8A00'),
                emissiveIntensity: 2
            }),
            new THREE.MeshStandardMaterial({
                color: new THREE.Color('#0033FF'),
                emissive: new THREE.Color('#0033FF'),
                emissiveIntensity: 2
            })
        ];
        this.threeJsLogoMaterial = new THREE.MeshStandardMaterial({
            color: new THREE.Color('#7469F2'),
            emissive: new THREE.Color('#7469F2'),
            emissiveIntensity: 4
        });

        this.googleHomeLEDMaterials = [
            new THREE.MeshStandardMaterial({
                color: new THREE.Color('#FF0000'),
                emissive: new THREE.Color('#FF0000'),
                emissiveIntensity: 4
            }),
            new THREE.MeshStandardMaterial({
                color: new THREE.Color('#00FF00'),
                emissive: new THREE.Color('#00FF00'),
                emissiveIntensity: 4
            }),
            new THREE.MeshStandardMaterial({
                color: new THREE.Color('#0000FF'),
                emissive: new THREE.Color('#0000FF'),
                emissiveIntensity: 4
            })
        ];
        
        this.lampBulbMaterial = new THREE.MeshStandardMaterial({
            color: new THREE.Color('#FFB600'),
            emissive: new THREE.Color('#FFB600'),
            emissiveIntensity: 10
        });

        this.treeLampShadeInnerMaterial = new THREE.MeshStandardMaterial({
            color: new THREE.Color('#FFFFFF'),
            metalness: 0.7,
            roughness: 0.1,
            side: THREE.DoubleSide
        });  
        
        this.hangingLampShadeMaterial = new THREE.MeshPhysicalMaterial({
            color: new THREE.Color('#FFB600'),
            metalness: 0.0,
            roughness: 0.3,
            side: THREE.DoubleSide,
            specularIntensity: 0,
            ior: 1.1,
            iridescenceIOR: 0.2,
            iridescenceIOR: 2.3,
            transmission: 0.7,
            opacity: 1            
        });

        this.pcCabinetFanMaterials = [
            new THREE.MeshStandardMaterial({
                color: new THREE.Color('#BFBFBF'),
                emissive: new THREE.Color('#BFBFBF'),
                emissiveIntensity: 2
            }),
            new THREE.MeshStandardMaterial({
                color: new THREE.Color('#FF0000'),
                emissive: new THREE.Color('#FF0000'),
                emissiveIntensity: 4
                })                      
        ];
        
        this.cpuConnectorsMaterial = new THREE.MeshStandardMaterial({
            color: new THREE.Color('#FF8F00'),
            emissive: new THREE.Color('#FF8F00'),
            emissiveIntensity: 3
        });

        this.cpuCoolerMaterials = [
            new THREE.MeshStandardMaterial({
                color: new THREE.Color('#FFFFFF'),
                emissive: new THREE.Color('#494949'),
                emissiveIntensity: 3
            }),
            new THREE.MeshStandardMaterial({
                color: new THREE.Color('#FFFFFF'),
                emissive: new THREE.Color('#FFFFFF'),
                emissiveIntensity: 3
            })
        ];
        
        this.pcRAMMaterials = [
            new THREE.MeshStandardMaterial({
                color: new THREE.Color('#FF0000'),
                emissive: new THREE.Color('#FF0000'),
                emissiveIntensity: 4
            }),
            new THREE.MeshStandardMaterial({
                color: new THREE.Color('#FFFF00'),
                emissive: new THREE.Color('#FFFF00'),
                emissiveIntensity: 4
            })
        ];
        this.pcCabinetMaterials = [
            // Black Metal Frame
            new THREE.MeshStandardMaterial({
                color: new THREE.Color('#191919'),
                metalness: 1,
                roughness: 0.7
            }),
            // Tinted Glass Covers
            new THREE.MeshPhysicalMaterial({
                color: new THREE.Color('#e3e3e3'),
                ior: 1,
                metalness: 0,
                roughness: 0,
                transmission: 0.8,
                reflectivity: 0.2
            }),
            // GPU Case Grey
            new THREE.MeshStandardMaterial({
                color: new THREE.Color('#E7E7E7'),
                metalness: 1,
                emissive: new THREE.Color('#A2A2A2'),
                emissiveIntensity: 0.2
            }),
            // GPU Fan Rim
            new THREE.MeshStandardMaterial({
                color: new THREE.Color('#FFFFFF'),
                emissive: new THREE.Color('#FFFFFF'),
                emissiveIntensity: 1
            }),
            new THREE.MeshStandardMaterial({
                color: new THREE.Color('#000000'),
                emissive: new THREE.Color('#000000'),
                emissiveIntensity: 4
            })
        ];
        
        this.gpuFanMaterials = [
            new THREE.MeshStandardMaterial({
                color: new THREE.Color('#FFFFFF'),
                emissive: new THREE.Color('#FFFFFF'),
                emissiveIntensity: 4
            }),
            new THREE.MeshStandardMaterial({
                color: new THREE.Color('#00B0FF'),
                emissive: new THREE.Color('#00B0FF'),
                emissiveIntensity: 4
            })
        ];

        this.gpuNameMaterial = new THREE.MeshStandardMaterial({
            color: new THREE.Color('#00FF17'),
            emissive: new THREE.Color('#00FF17'),
            emissiveIntensity: 4
        });
        
        this.pcCabinetRadiatorFanMaterials = [
            new THREE.MeshStandardMaterial({
                color: new THREE.Color('#FFFF00'),
                emissive: new THREE.Color('#FFFF00'),
                emissiveIntensity: 4
            }),
            new THREE.MeshStandardMaterial({
                color: new THREE.Color('#00B0FF'),
                emissive: new THREE.Color('#00B0FF'),
                emissiveIntensity: 4
            })
        ];
        
        this.macbookBacklitKeyboardLightMaterial = new THREE.MeshStandardMaterial({
            color: new THREE.Color('#FFFFFF'),
            emissive: new THREE.Color('#FFFFFF'),
            emissiveIntensity: 4
        });

        this.hololensLEDMaterial = new THREE.MeshStandardMaterial({
            color: new THREE.Color('#00FF17'),
            emissive: new THREE.Color('#00FF17'),
            emissiveIntensity: 4
        });

        // Non Glowing Materials       
        this.resumeMaterial = new THREE.MeshStandardMaterial();
        // NOTE - re-exported GLTF with Vertex normal data to get better results and disabled FLAT SHADING
        //this.resumeMaterial.flatShading = true
        this.resumeAbridgedMaterial = new THREE.MeshStandardMaterial();
        this.draftingTablePaintingMaterial = new THREE.MeshStandardMaterial();
        this.stickNoteMaterial = new THREE.MeshStandardMaterial();

        // Emissive Textured Material
        this.kurzgezagtLogoMaterial = new THREE.MeshStandardMaterial();
        this.monitorScreenMaterial = new THREE.MeshBasicMaterial({
            color: new THREE.Color('#000000')
        });
        this.macbookScreenMaterial = new THREE.MeshStandardMaterial();
        this.phoneScreenMaterial = new THREE.MeshStandardMaterial();

        this.gamingChairSeatMaterials = [
            new THREE.MeshStandardMaterial({
                color: new THREE.Color('#000000'),
                metalness: 0,
                roughness: 0.5,
                flatShading: false
            }),
            new THREE.MeshBasicMaterial({
                color: new THREE.Color('#FF0000'),
                // metalness: 0,
                // roughness: 1,
                // flatShading: false,
                // specularIntensity: 0
            })
        ];

        this.documentFolderMaterials = [
            new THREE.MeshStandardMaterial({
                color: new THREE.Color('#51669F')
            }),
            new THREE.MeshStandardMaterial({
                color: new THREE.Color('#FFF5C8')
            })
        ];

        this.monitorONOFFbuttonMaterial = new THREE.MeshStandardMaterial({
            color: new THREE.Color('#FFFF00'),
            emissive: new THREE.Color('#FFFF00'),
            emissiveIntensity: 6
        })
        //this.testMaterial = new THREE.MeshBasicMaterial({color: new THREE.Color('#FFFFFF')})
    }

    mapTextures()
    {
        // map baked textures
        this.workDenSceneBakedItemsModelMaterial01 = new THREE.MeshBasicMaterial();
        this.resourcesRef.objectsLoaded.workDenSceneMergedTexture001.flipY = false;
        this.workDenSceneBakedItemsModelMaterial01.map = this.resourcesRef.objectsLoaded.workDenSceneMergedTexture001;
        this.workDenSceneBakedItemsModelMaterial01.needsUpdate = true;

        this.workDenSceneBakedItemsModelMaterial02 = new THREE.MeshBasicMaterial({ map: this.resourcesRef.objectsLoaded.workDenSceneMergedTexture002 })
        this.workDenSceneBakedItemsModelMaterial02.needsUpdate = true;
        //this.workDenSceneMaterial.side = THREE.DoubleSide; 
        this.workDenScenePhotoTexturedObjectsMaterial = new THREE.MeshBasicMaterial({ map: this.resourcesRef.objectsLoaded.workDenSceneMergedPhotoTexturesBaked })
        this.workDenScenePhotoTexturedObjectsMaterial.needsUpdate = true;

        // map unbaked textures
        // fetch loaded materials for material mapped objects and assign texture to them        
        this.resumeMaterial.map = this.resourcesRef.objectsLoaded.resumeTexture;
        this.resumeMaterial.needsUpdate = true;
        this.resumeAbridgedMaterial.map = this.resourcesRef.objectsLoaded.resumeAbridgedTexture;
        this.resumeAbridgedMaterial.needsUpdate = true;
        this.draftingTablePaintingMaterial.map = this.resourcesRef.objectsLoaded.lokmanyaTilakPaintingTexture;
        this.draftingTablePaintingMaterial.needsUpdate = true;
        
        this.kurzgezagtLogoMaterial = new THREE.MeshStandardMaterial({
            //color: new THREE.Color('#FFFFFF'),
            emissive:new THREE.Color('#FFFFFF'),             
            metalness: 0,
            roughness: 0,
            emissiveIntensity: 1
        });
        this.kurzgezagtLogoMaterial.map = this.resourcesRef.objectsLoaded.kurzgezagtLogoTexture;
        this.kurzgezagtLogoMaterial.emissiveMap = this.resourcesRef.objectsLoaded.kurzgezagtLogoTexture;
        this.kurzgezagtLogoMaterial.needsUpdate = true;

        this.macbookScreenMaterial = new THREE.MeshStandardMaterial({
            //color: new THREE.Color('#FFFFFF'),
            emissive:new THREE.Color('#FFFFFF'),             
            metalness: 0,
            roughness: 0,
            emissiveIntensity: 1
        });
        this.macbookScreenMaterial.map = this.resourcesRef.objectsLoaded.firewatchWallpaperTexture;
        this.macbookScreenMaterial.emissiveMap = this.resourcesRef.objectsLoaded.firewatchWallpaperTexture;
        this.macbookScreenMaterial.needsUpdate = true;
        
        this.phoneScreenMaterial = new THREE.MeshStandardMaterial({
            //color: new THREE.Color('#FFFFFF'),
            emissive:new THREE.Color('#FFFFFF'),             
            metalness: 0,
            roughness: 0,
            emissiveIntensity: 1
        });
        this.phoneScreenMaterial.map = this.resourcesRef.objectsLoaded.phoneScreenTexture;
        this.phoneScreenMaterial.emissiveMap = this.resourcesRef.objectsLoaded.phoneScreenTexture;
        this.phoneScreenMaterial.needsUpdate = true;

        this.stickNoteMaterial.map = this.resourcesRef.objectsLoaded.stickyNoteBackArrowTexture;
        this.stickNoteMaterial.needsUpdate = true;

        this.resourcesRef.trigger('texturesMapped')
    }

    update()
    {
        if (this.hololensLEDMaterial)
        {
            this.hololensLEDMaterial.emissiveIntensity = (4 * Math.sin(this.timeRef.elapsedTime * 0.002));
        }

        if (this.gpuNameMaterial)
        {
            this.gpuNameMaterial.emissiveIntensity = Math.abs(Math.sin(this.timeRef.elapsedTime * 0.0005));
        }

        // if (this.monitorONOFFbuttonMaterial)
        // {
        //     this.monitorONOFFbuttonMaterial.emissiveIntensity = 4 * Math.abs(Math.sin(this.timeRef.elapsedTime * 0.0005));
        // }

        // Google Home LED Breathing Animation
        if (this.googleHomeLEDMaterials)
        {
            //var progress = (this.timeRef.elapsedTime % 3) / 3;

            this.googleHomeLEDMaterials[0].emissiveIntensity = Math.abs(4 * Math.sin(this.timeRef.elapsedTime * 0.0005));
            this.googleHomeLEDMaterials[1].emissiveIntensity = Math.abs(4 * Math.sin((this.timeRef.elapsedTime * 0.0005) + (2 * Math.PI / 3)));
            this.googleHomeLEDMaterials[2].emissiveIntensity = Math.abs(4 * Math.sin((this.timeRef.elapsedTime * 0.0005) + (Math.PI / 3)));
        }
    }
}