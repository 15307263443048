export default 
[
    // Models
    {
        name : 'workDenSceneMerged001',
        type :'dracoGltfModel',
        path :'models/Workden/workDenSceneMerged.001.glb'
    },
    {
        name : 'workDenSceneMerged002',
        type :'dracoGltfModel',
        path :'models/Workden/workDenSceneMerged.002.glb'
    },    
    {
        name : 'workDenSceneMaterialMappedObjects',
        type : 'dracoGltfModel',
        path : 'models/Workden/workDenSceneMaterialMappedObjects.glb'
    },
    {
        name : 'workDenSceneMergedPhotoTextureObjects',
        type :'dracoGltfModel',
        path :'models/Workden/workDenSceneMergedPhotoTextures.glb'
    },
    {
        name : 'workDenSceneMiscObjects',
        type :'dracoGltfModel',
        path :'models/Workden/workDenSceneMiscObjects.glb'
    },
    // Baked Textures
    {
        name : 'workDenSceneMergedTexture001',
        type : 'texture',
        path : 'textures/baked/workDenSceneMergedTexture001.jpg'
    },
    {
        name : 'workDenSceneMergedTexture002',
        type : 'texture',
        path : 'textures/baked/workDenSceneMergedTexture002.jpg'
    },
    {
        name : 'workDenSceneMergedPhotoTexturesBaked',
        type : 'texture',
        path : 'textures/baked/workDenSceneMergedPhotosTexture.jpg'
    },    
    // Regular Textures
    {
        name : 'stickyNoteBackArrowTexture',
        type : 'texture',
        path : 'textures/backArrow.jpg'
    },
    {
        name : 'resumeTexture',
        type : 'texture',
        path : 'textures/unbaked/Nikhil_CV.PNG'
    },
    {
        name : 'resumeAbridgedTexture',
        type : 'texture',
        path : 'textures/unbaked/Nikhil_CV_abridged.PNG'
    },
    {
        name : 'kurzgezagtLogoTexture',
        type : 'texture',
        path : 'textures/unbaked/Kurzgesagt.png'
    },
    {
        name : 'firewatchWallpaperTexture',
        type : 'texture',
        path : 'textures/unbaked/FirewatchWallpaper.jpg'
    },
    {
        name : 'phoneScreenTexture',
        type : 'texture',
        path : 'textures/unbaked/PhoneScreen.jpg'
    },
    {
        name : 'lokmanyaTilakPaintingTexture',
        type : 'texture',
        path : 'textures/unbaked/Painting.jpg'
    },
    {
        name : 'perlinNoiseTexture',
        type : 'texture',
        path : 'textures/perlinNoiseTexture.png'
    }
]

