varying vec2 vUv;
uniform float uTime;
uniform sampler2D uPerlinTexture;

vec2 rotate2D(vec2 value, float angle)
{
    float s = sin(angle);
    float c = cos(angle);
    mat2 m = mat2(c, s, -s, c);
    return m * value;
}

void main()
{
    vec3 newPosition = position;

    // Twist the vertices
    //float angle = 2.0;
    // To rotate vertices according to the elevation on Y axis
    //float angle = newPosition.y;

    // Adding randomness to the twist using values from the Perlin Texture
    // Multiplying uv.y by 0.2 to reduce the frequency of twist along the Y axis, and subtract by uTime to animate the pattern
    float twistPerlin = texture(uPerlinTexture, vec2(0.5, uv.y * 0.2 - uTime * 0.00005)).r;
    float angle = twistPerlin;
    // Multiplying angle by 10 to make the twisting stronger
    newPosition.xz = rotate2D(newPosition.xz, angle * 10.0);

    // Wind
    //float windPerlin = texture(uPerlinTexture, vec2(0.25, uTime)).r;

    // We also need the value to be as much positive as negative
    // Because windOffset.x is extracted from the Perlin texture, it goes from 0 to 1
    // Subtracting 0.5 results in values ranging from -0.5 to +0.5
    float windPerlinX = texture(uPerlinTexture, vec2(0.25, uTime * 0.0001)).r - 0.5;
    float windPerlinZ = texture(uPerlinTexture, vec2(0.75, uTime * 0.0001)).r - 0.5;

    vec2 windOffset = vec2(windPerlinX, windPerlinZ);
    // Multiplying windOffset by uv.y to make it stick to the initial position
    // windOffset *= uv.y * 10.0;
    // We want the strength to be very low at the bottom, to increase slowly at first, 
    // and then to increase fast when reaching the top of the smoke, which we can get using a power
    windOffset *= pow(uv.y, 2.0) * 10.0;
    newPosition.xz += windOffset;

    // Final position
    gl_Position = projectionMatrix * modelViewMatrix * vec4(newPosition, 1.0);

    // Varyings
    vUv = uv;
}