import * as THREE from 'three'
import EventEmitter from "./EventEmitter.js";
import { GLTFLoader } from "three/examples/jsm/loaders/GLTFLoader.js";
import { DRACOLoader } from 'three/examples/jsm/loaders/DRACOLoader';

export default class Resources extends EventEmitter
{
    constructor(pSources)
    {
        super();

        // Options / references to file sources
        this.sources = pSources;

        //console.log(this.sources);
        this.objectsLoaded = {};
        this.materialsLoaded = {};
        this.toLoad = this.sources.length;
        this.loaded = 0;

        this.setLoaders();
        this.startLoading();
    }

    setLoaders()
    {
        this.loaders = {};
        this.loaders.gltfLoader = new GLTFLoader();
        this.loaders.textureLoader = new THREE.TextureLoader();
        this.loaders.cubeTextureLoader = new THREE.CubeTextureLoader();
        this.loaders.dracoLoader = new DRACOLoader
        this.loaders.dracoLoader.setDecoderPath('/draco/')        
        this.loaders.gltfLoader.setDRACOLoader(this.loaders.dracoLoader)
    }
    
    startLoading()
    {
        // Load each source
        for (const source of this.sources)
        {
            if (source.type == 'dracoGltfModel')
            {
                this.loaders.gltfLoader.load(
                    source.path,
                    (file) => 
                    {
                        //console.log(source, file);
                        this.sourceLoaded(source, file);
                    }
                );
            }
            else if (source.type == 'gltfModel')
            {
                this.loaders.gltfLoader.load(
                    source.path,
                    (file) => 
                    {
                        //console.log(source, file);
                        this.sourceLoaded(source, file);
                    }
                );
            }
            else if (source.type == 'texture')
            {
                this.loaders.textureLoader.load(
                    source.path,
                    (file) => 
                    {
                        //console.log(source, file);
                        file.flipY = false
                        file.colorSpace = THREE.SRGBColorSpace;
                        this.sourceLoaded(source, file);
                    }
                );
            }
            else if (source.type == 'cubeTexture')
            {
                this.loaders.cubeTextureLoader.load(
                    source.path,
                    (file) => 
                    {
                        //console.log(source, file);
                        this.sourceLoaded(source, file);
                    }
                );
            }
        }
    }

    sourceLoaded(pSource, pFile)
    {
        this.trigger('itemLoaded')

        this.objectsLoaded[pSource.name] = pFile;        

        // Load all the mapped materials
        if(pFile.scene)
        {            
            pFile.scene.traverse((child) => {
                // Check if the child has a material and a name assigned from Blender
                if (child.material && child.material.name)
                {
                    // Log the type of material
                    //console.log('Object :', child.parent.name);
                    //console.log('Material :',child.material.name);

                    this.materialsLoaded[child.material.name] = child.material;
                }
            })            
        }

        this.loaded++;
        if(this.loaded === this.toLoad)
        {
            console.log('All files loaded. Ready to go!');
            this.trigger('ready');
        }
    }
}

// gltfLoader.load(
//     '/models/desktopPcCabinet.glb',
//     (gltf) => {
//         console.log("success");
//         //console.log(gltf.scene);
//         // Using while() since adding objects from gltf object removes them from the list/alters the gltf mesh array
//         console.log(gltf.scene);
//         while (gltf.scene.children.length)
//         {            
//             scene.add(gltf.scene.children[0]);
//         }      
        
//         gltf.parser.getDependencies( 'material' ).then( ( materials ) => {

//             console.log( materials );
        
//         } );
//     },
//     (progress) => {
//         console.log("progress");
//         console.log(progress);
//     },
//     (error) => {
//         console.log("error");
//         console.log(error);
//     }
// );