/**
 * Loading Model using stock GLTF and DRACO loader and wrapping it inside a React Component
 */

import React, { useState, forwardRef, useEffect, useRef } from 'react'
import { events, useFrame, useLoader } from '@react-three/fiber'
import { GLTFLoader } from "three/examples/jsm/loaders/GLTFLoader.js";
import { DRACOLoader } from 'three/examples/jsm/loaders/DRACOLoader.js';
import { ExtendedMaterial } from 'three-extended-material/react'
import { MeshStandardMaterial } from 'three'
import { useGLTF } from '@react-three/drei'
import gsap from 'gsap'

const LandingPageStage = forwardRef((props, forwardRef) => {  

  const doorRef = useRef();
  
  useEffect(() => {    
    
    if(props.isMainSceneReady)
    {
      //console.log("door animated");      
      gsap.to(doorRef.current.rotation, 
        { y: - Math.PI / 2.7 , 
          duration: 3, 
          ease: 'linear' 
        });
    }   
    
    
  },[props.isMainSceneReady]);

  const onDoorMouseEnterHandler = () => {
    if (props.isMainSceneReady)
    {
      document.body.style.cursor = 'grab';
    }    
  }

  const onDoorMouseExitHandler = () => {    
    if (props.isMainSceneReady)
    {
      document.body.style.cursor = 'auto';
    }    
  }

  const cineStudioModel = useLoader(
    GLTFLoader,
    './models/CineStudio.glb',
    (loader) =>
    {
      const dracoLoader = new DRACOLoader();
      dracoLoader.setDecoderPath('./draco/');
      loader.setDRACOLoader(dracoLoader);
    }
  );
  //console.log(cineStudioModel.nodes);
  
  return (
    <group {...props} dispose={null} name={"importedModels"}>
      
      <mesh ref = {forwardRef}
        castShadow
        receiveShadow
        geometry = { cineStudioModel.nodes.NameText.geometry } 
        position = {[0.455, 2.52, -17.875]}
      >
        <ExtendedMaterial 
          superMaterial = {MeshStandardMaterial} 
          extensions = {[meshFillShaderExtension]} 
          uFillValue = {props.meshFillAmount}
        />
      </mesh>

      <mesh
        castShadow
        receiveShadow
        geometry={cineStudioModel.nodes.StageDoorFrame.geometry}        
        position={[-0.117, 1.648, -17.719]}
      >
        <meshStandardMaterial 
          color="#000000"           
          roughness={0.3} 
          metalness={0}
        />
      </mesh>

      <mesh ref={doorRef}
        castShadow 
        receiveShadow 
        geometry={cineStudioModel.nodes.StageDoor.geometry} 
        position={[-1.343, 1.648, -17.719]}
      >
        <meshStandardMaterial 
          color="#000000"           
          roughness={0.3} 
          metalness={0}
        />
      </mesh>

      <mesh 
        castShadow
        receiveShadow
        geometry={cineStudioModel.nodes.StageDoorBacklight.geometry}        
        position={[-0.117, 1.681, -17.904]}
        scale={[1.04, 1.018, 1]}
        onClick={ props.onDoorClicked }
        onPointerEnter={ onDoorMouseEnterHandler }
        onPointerLeave={ onDoorMouseExitHandler }
      >
        <meshStandardMaterial 
          color="#ffffff"
          emissive= "#ffffff"
          emissiveIntensity={1}
          roughness={0.3} 
          metalness={0}
        />
      </mesh>     

      <mesh
        castShadow
        receiveShadow
        geometry={cineStudioModel.nodes.StageDoorknob.geometry}        
        position={[0.85, 1.65, -17.693]}
        rotation={[Math.PI / 2, 0, 0]}
        scale={0.13}
      >
        <meshStandardMaterial 
          color="#ffffff"
        />
      </mesh> 

      <mesh 
        castShadow 
        receiveShadow 
        geometry={cineStudioModel.nodes.LowpolyGuy.geometry} 
        rotation={[Math.PI, 0, Math.PI]}
        onPointerEnter={ (event) => event.stopPropagation() }
      >
        <meshStandardMaterial 
          color="#202020" 
          roughness={0.1} 
          metalness={0} 
        />
      </mesh>

    </group>
  );


});

export default LandingPageStage;

const meshFillShaderExtension = {
  name: 'meshFillShader',
  uniforms: {
    uFillValue: 0.5
  },

  // Inject the logic to pass UV values from Vertex to Fragment Shader as 'varyings'
  vertexShader: (shader, type) => {
    shader = `
      varying vec2 vUv;
      
      ${shader.replace(
        `void main() {`,
        `        
        void main() {
            vUv = uv;
        `
      )}
    `

    return shader
  },

  fragmentShader: (shader, type) => {
    shader = `
      uniform float uFillValue;
      varying vec2 vUv;
      
      ${shader.replace(
        '#include <color_fragment>',
        // here we inject the meshFill color shader logic right before the <color_fragment>,
        // where gl_FragColor is set according to the lighting calculation
        // https://github.com/mrdoob/three.js/blob/master/src/renderers/shaders/ShaderChunk/output_fragment.glsl.js
        `
        #include <color_fragment>
  
        // Determine the color based on the U coordinate and the fill value
        vec4 color = vUv.x < uFillValue ? vec4(1.0, 1.0, 1.0, 1.0) * diffuseColor : vec4(1.0, 0.0, 0.0, 1.0) * diffuseColor;
        diffuseColor = color;

        //Test
        //diffuseColor = vec4(1, 1, 0, 1);
        `
      )}
    `
    return shader
  }
}

useGLTF.preload('./models/CineStudio.glb');