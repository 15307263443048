uniform sampler2D uPerlinTexture;
uniform float uTime;

varying vec2 vUv;


void main()
{
    // Smoke

    // Scale and animate
    vec2 smokeUv = vUv;
    // smokeUv.x *= 0.5;
    // smokeUv.y *= 0.3;
    smokeUv.y -= uTime * 0.00003;

    //vec4 smoke = texture(uPerlinTexture, vUv);
    // Since Perlin texture is a  grayscale image, we need only one channel and we can use the RED channel
    float smoke = texture(uPerlinTexture, smokeUv).r;

    // Remap (to make dark areas darker)
    smoke = smoothstep(0.2, 1.0, smoke);

    // Edges
    //smoke = 1.0;
    smoke *= smoothstep(0.0, 0.1, vUv.x);
    smoke *= smoothstep(1.0, 0.9, vUv.x);
    smoke *= smoothstep(0.0, 0.1, vUv.y);
    smoke *= smoothstep(1.0, 0.4, vUv.y);

    // Final color
    //gl_FragColor = vec4(vUv, 1.0, 1.0);
    gl_FragColor = vec4(1.0, 1.0, 1.0, smoke);

    // Testing color
    //gl_FragColor = vec4(1.0, 0.0, 0.0, 1.0);


    //#include <tonemapping_fragment>
    //#include <colorspace_fragment>
}